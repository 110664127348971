import domReady from '@roots/sage/client/dom-ready';
import sal from 'sal.js';
import simpleParallax from 'simple-parallax-js';
import './scollHeader.js';

const maxVisibleWorks = 8;

const applyInitialHiddenFilters = () => {
  const allWorkItems = Array.from(document.querySelectorAll('.work__item'));
  allWorkItems.forEach((workItem, index) => {
    if (index >= maxVisibleWorks) {
      workItem.classList.add('work__item--initial-hidden');
    }
  });
}

const applyFilters = (state, value) => {
  const currentWorkItems = document.querySelectorAll(`.work__item[data-type="${value}"]`);
  Array.from(currentWorkItems).forEach((workItem, index) => {
    if (state) {
      workItem.classList.remove('work__item--hidden');
    } else {
      workItem.classList.add('work__item--hidden');
    }
  });
  const allVisibleWorkItems = Array.from(document.querySelectorAll('.work__item:not(.work__item--hidden)'));
  allVisibleWorkItems.forEach(visibleWorkItem => {
    visibleWorkItem.classList.remove('work__item--initial-hidden')
    visibleWorkItem.classList.remove('work__item--last');
  });

  const showAll = document.querySelector('.works-page__show-all');

  if (state) {
    if (showAll) {
      showAll.style.display = 'block';
    }
    applyInitialHiddenFilters();
  } else if (showAll) {
    showAll.style.display = 'none';
  }

  if (allVisibleWorkItems.length) {
    allVisibleWorkItems[allVisibleWorkItems.length - 1].classList.add('work__item--last');
  }
}
const initWorkFilters = () => {
  const toggle = document.querySelector('.work__filter>span');
  const filterWrapper = document.querySelector('.work__filter-items');
  const filters = document.querySelectorAll('.work__filter-item input');

  toggle?.addEventListener('click', () => {
    filterWrapper.classList.toggle('work__filter-items--hidden');
  });

  Array.from(filters).forEach(filter => {
    applyFilters(filter.checked, filter.dataset.value);

    filter.addEventListener('click', () => {
      applyFilters(filter.checked, filter.dataset.value);
    })
  })

  applyInitialHiddenFilters();
}

/**
 * Application entrypoint
 */
domReady(async () => {
  const scrollAnimations = sal({
    threshold: .35,
  });

  const videos = document.querySelectorAll('body.about .video-container video')

  if (videos.length) {
    new simpleParallax(videos, { scale: 1.2});
  }

  const hoverVideos = document.querySelectorAll('video:not([autoplay])');
  Array.from(hoverVideos).forEach(video => {
    let wrapper = video;
    if (video.closest('.work-item')) {
      wrapper = video.closest('.work-item');
    } else if ( video.closest('.work__item')) {
      wrapper = video.closest('.work__item');
    }
    wrapper.addEventListener("mouseover", () => {
      video.play();
    });

    wrapper.addEventListener("mouseleave", () => {
      if (!!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)) {
        video.pause();
      }
    });
  })

  const headerNav = document.querySelector('.header__nav');
  const headerButton = document.querySelector('.header__button');
  const bar1 = headerButton?.querySelector("span:nth-child(1)");
  const bar2 = headerButton?.querySelector("span:nth-child(2)");
  const bar3 = headerButton?.querySelector("span:nth-child(3)");
  let isAnimating = false;
  const initialColor = window.getComputedStyle(bar1, null).getPropertyValue('background-color');

  headerButton?.addEventListener('click', () => {
    if (isAnimating) {
      return;
    }
    isAnimating = true;
    setTimeout(() => {
      isAnimating = false;
    }, 1800);
    headerNav.classList.toggle('header__nav--visible');
    document.body.classList.toggle('modal-open');


    if (headerNav.classList.contains("header__nav--visible")) {
      Velocity(bar1, { top: "50%", backgroundColor: "#302e31" }, {duration: 200, easing: "swing"},);
      Velocity(bar2, { backgroundColor: "#302e31" }, {duration: 200, easing: "swing"});
      Velocity(bar3, { top: "50%", backgroundColor: "#302e31" }, {duration: 200, easing: "swing"});
      Velocity(bar3, {rotateZ:"90deg"}, {duration: 800, delay: 200, easing: [500,20] });
      Velocity(headerButton, {rotateZ:"135deg"}, {duration: 800, delay: 200, easing: [500,20] });
    } else {
      bar1.style.backgroundColor = initialColor;
      bar2.style.backgroundColor = initialColor;
      bar3.style.backgroundColor = initialColor;
      Velocity(headerButton, "reverse");
      Velocity(bar3, {rotateZ:"0deg"}, {duration: 800, easing: [500,20] });
      Velocity(bar3, { top: "100%" }, {duration: 200, easing: "swing"});
      Velocity(bar1, "reverse", {delay: 800});
    }
  });

  const accordionElements = document.querySelectorAll('.about-accordion');
  if (Array.from(accordionElements).length) {
    const accordion = new Accordion('.about-accordion');
    accordion.open(0);
  }

  const showAllButton = document.querySelector('.works__show-all');
  const workItems = document.querySelectorAll('.work-item');
  showAllButton?.addEventListener('click', () => {
    Array.from(workItems).forEach(workItem => {
      workItem.style.display = 'flex';
    });
    showAllButton.remove();
  });

  initWorkFilters();

  const worksPageShowAllButton = document.querySelector('.works-page__show-all');
  const worksPageItems = document.querySelectorAll('.work__item--initial-hidden');
  worksPageShowAllButton?.addEventListener('click', () => {
    Array.from(worksPageItems).forEach(workItem => {
      console.log(workItem);
      workItem.classList.remove('work__item--initial-hidden');
    });
    worksPageShowAllButton.remove();
  });
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
// import.meta.webpackHot?.accept(console.error);
